import React from "react";
import BookCoverTitle from "./book_cover_title";
import { motion } from "framer-motion";
import books from "../../../models/book";

const Title = () => {
  const iconsAnimationDelayMs = 800;

  const positions = [
    {
      x_position: -551,
      y_position: -91,
      mobile_x: 220,
      mobile_y: 370,
    },
    {
      x_position: -317,
      y_position: -55,
      mobile_x: -160,
      mobile_y: -90,
    },
    {
      x_position: -726,
      y_position: 1,
      mobile_x: -45,
      mobile_y: -120,
    },
    {
      x_position: -469,
      y_position: 36,
      mobile_x: -210,
      mobile_y: 370,
    },
    {
      x_position: -660,
      y_position: 169,
      mobile_x: 30,
      mobile_y: -170,
    },
    {
      x_position: -776,
      y_position: 333,
      mobile_x: 85,
      mobile_y: -112,
    },
    {
      x_position: -399,
      y_position: 273,
      mobile_x: 220,
      mobile_y: -60,
    },
    {
      x_position: -562,
      y_position: 407,
      mobile_x: 105,
      mobile_y: 347,
    },
    {
      x_position: -283,
      y_position: 472,
      mobile_x: 139,
      mobile_y: 446,
    },
    {
      x_position: 328,
      y_position: -55,
      mobile_x: 28,
      mobile_y: 481,
    },
    {
      x_position: 593,
      y_position: -115,
      mobile_x: 0,
      mobile_y: 399,
    },
    {
      x_position: 733,
      y_position: -1,
      mobile_x: -102,
      mobile_y: 437,
    },
    {
      x_position: 503,
      y_position: 4,
      mobile_x: -136,
      mobile_y: 336,
    },
    {
      x_position: 643,
      y_position: 169,
      mobile_x: -193,
      mobile_y: -180,
    },
    {
      x_position: 466,
      y_position: 256,
      mobile_x: 0,
      mobile_y: 0,
    },
    {
      x_position: 722,
      y_position: 366,
      mobile_x: 0,
      mobile_y: 0,
    },
    {
      x_position: 538,
      y_position: 456,
      mobile_x: -90,
      mobile_y: -161,
    },
    {
      x_position: 294,
      y_position: 420,
      mobile_x: 184,
      mobile_y: -120,
    },
  ];

  const shuffledBooks = books.sort(() => Math.random() - 0.5);

  return (
    <section className="homepage-v2-hero flex flex-col items-stretch justify-center border-[16px] border-white relative overflow-hidden">
      <div className="flex-1 flex md:block flex-col items-stretch justify-center w-full bg-blue mx-auto md:max-h-max max-h-[90vh] pt-[150px] pb-[180px] md:p-[260px_0] relative rounded-[16px] md:rounded-[24px] overflow-hidden">
        {/* Background with radial gradient */}
        <div className="absolute inset-0 rounded-[16px] md:rounded-[24px] overflow-hidden z-0">
          <div
            style={{
              backgroundImage:
                "radial-gradient(circle, #a6deff 0.06rem, transparent 0)",
              filter: "blur(1px)",
              opacity: 0.7,
            }}
            className="absolute inset-0 bg-[size:30px_30px]"
          ></div>
        </div>

        {/* Book covers */}
        <div className="absolute inset-0 -mt-[150px] flex items-center justify-center z-0">
          {positions.map((position, index) => {
            return (
              <BookCoverTitle
                book={shuffledBooks[index]}
                key={"cover-title" + index}
                coverImage={`/covers/chips/${shuffledBooks[index].cover}`}
                height={88}
                mobile_height={48}
                position_x={(position.x_position + 50) / 1.1}
                mobile_position_x={position.mobile_x}
                position_y={position.y_position - 10}
                mobile_position_y={
                  position.mobile_y > 0
                    ? position.mobile_y
                    : position.mobile_y * 1.3
                }
                mobile_radius={18}
                radius={18}
                startDelayMs={iconsAnimationDelayMs * 1.5}
              />
            );
          })}
        </div>

        {/* Main content */}
        <div className="container md:site-grid text-white relative z-10 mx-auto">
          <div className="col-start-3 col-span-8 flex flex-col items-center text-center">
            <motion.div
              initial={{ opacity: 0, scale: 0.98 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.1,
                delay: iconsAnimationDelayMs / 1000,
              }}
              className="mb-[6vw] md:mb-[65px] relative flex flex-row justify-center items-center"
            >
              <img
                src="https://images.readwithjoey.com/joey/logos/joey-books-white-logo.webp"
                className="w-[30vw] md:max-h-max max-h-[90px] md:w-auto object-contain md:h-[150px]"
                alt="Joey Logo"
              />
            </motion.div>

            <motion.div
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <motion.div
                initial={{ opacity: 0, filter: "blur(10px)" }}
                animate={{ opacity: 1, filter: "blur(0px)" }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="flex flex-col items-center text-center gap-y-[16px] md:gap-y-[24px] mt-[-20px]"
              >
                <div>
                  <h1 className="text-3xl md:text-5xl font-[600]">
                    The freedom to read
                  </h1>
                  <p className="text-lg md:text-lg font-[500] mt-5 mx-10">
                    Inspire a love of reading with our digital read-along
                    library.
                  </p>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.9 }}
                className="pointer-events-auto flex flex-col md:flex-row justify-center items-center gap-x-[12px] gap-[16px] mt-[25px] md:mt-[35px]"
              >
                <a
                  href="https://apps.apple.com/us/app/joey-books/id6465174102"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/stores/AppStore.png"
                    className="w-[40vw] md:w-auto md:h-[52px] cursor-pointer"
                    alt="App Store"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.readwithjoey.joey_books"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/stores/PlayStore.png"
                    className="w-[40vw] md:w-auto md:h-[52px] cursor-pointer"
                    alt="Google Play"
                  />
                </a>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Title;
