import React from "react";

const PrivacyPolicy = () => {
  return (
    <main className="flex-1">
      <div className="mx-auto px-4 py-4 sm:px-8">
        <div className="flex w-full items-center justify-start">
          <div>
            <a href="/">
              <img
                className="h-6"
                src="/logo-black.svg"
                alt="Joey Logo black"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-4xl px-4 py-8 sm:px-8 sm:py-12">
        <div className="mb-12 space-y-4 text-lg leading-relaxed text-black text-justify">
          <h1 className="mb-8 text-2xl font-bold sm:text-4xl">
            Privacy Policy
          </h1>
        </div>
        <div className="mb-8 space-y-4 leading-relaxed text-black text-justify">
          <p className="font-bold">Joey Books Privacy Policy</p>

          <p>
            Joey Books Pty Ltd built the Joey Books app as a Commercial app.
            This SERVICE is provided by Joey Books Pty Ltd and is intended for
            use as is.
          </p>
          <p>
            This page is used to inform visitors regarding our policies with the
            collection, use, and disclosure of Personal Information if anyone
            decided to use our Service.
          </p>
          <p>
            If you choose to use our Service, then you agree to the collection
            and use of information in relation to this policy. The Personal
            Information that we collect is used for providing and improving the
            Service. We will not use or share your information with anyone
            except as described in this Privacy Policy.
          </p>
          <p>
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which is accessible at Joey Books unless
            otherwise defined in this Privacy Policy.
          </p>
        </div>
        <div className="mb-8 space-y-4 leading-relaxed text-black text-justify">
          <p className="font-bold">Information Collection </p>
          In providing our Service, we may collect the following information:
          <p>
            <span className="underline">Account Registration</span>{" "}
          </p>
          <p>
            To register for a Joey Books account, you may provide us with your
            email address. In some countries, you may also provide us with your
            phone number.
          </p>
          <p>
            You may also register for a Joey Books account using certain social
            logins, such as Google and Facebook. If you register for Joey Books
            using a social login, Joey Books may receive information about you
            from your social login provider, including your email address and
            contacts.
          </p>
          <p>
            <span className="underline">Child Profile Page</span>
          </p>
          <p>
            We may collect information about your child, such as their name and
            age. We do not collect this information directly from child users.
          </p>
          <p>
            <span className="underline">Use of App</span>
          </p>
          <p>
            We collect information about how you use the service. This includes,
            how long a user accesses our Service, what content a user views, and
            what actions taken in the use of the Service. We collect which books
            are read, how long a user reads a book, and how many books are read.
            This information is used to improve the Service and to provide you
            with a better experience.
          </p>
          <p>
            Link to privacy policy of third party service providers used by the
            app
          </p>
          <li>
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline bul"
            >
              Google Play Services
            </a>
          </li>
          <li>
            <a
              href="https://www.apple.com/au/legal/privacy/data/en/apple-pay/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline bul"
            >
              Apple Pay
            </a>
          </li>
        </div>
        <div className="mb-8 space-y-4 leading-relaxed text-black text-justify">
          <p className="font-bold">How we use personal information </p>
          <p>
            We use the information we collect to provide, maintain, and improve
            the Service. We may also use the information to communicate with you
            about the Service, to provide you with updates, and to respond to
            your requests.
          </p>
          <p>
            We use the information we collect to provide you with a personalised
            experience. We may use the information to recommend books to you
            based on your reading history and age.
          </p>
          <p>
            We use the information we collect to provide you with customer
            support. We may use the information to respond to your requests, to
            provide you with technical support, and to provide you with
            information about the Service.
          </p>
          <p>
            We use the information we collect to improve the Service. We may use
            the information to analyze how the Service is used, to identify
            trends, and to improve the Service.
          </p>
        </div>
        <div className="mb-8 space-y-4 leading-relaxed text-black text-justify">
          <p className="font-bold">Your data subject rights</p>
          <p>
            You also have the following rights in relation to the personal
            information we hold about you, in addition to any other rights
            required by applicable law:
          </p>
          <li>Right to access your personal information</li>
          <li>Right to rectify your personal information</li>
          <li>Right to erase your personal information</li>
          <li>Right to restrict our use of your personal information</li>
          <li>Right to object to our use of your personal information</li>

          <li>Right to lodge a complaint with the data protection authority</li>
          <p>
            If you wish to exercise any of these rights, please contact us at{" "}
            <span className="underline">hello@readwithjoey.com</span>
          </p>
        </div>
        <div className="mb-8 space-y-4 leading-relaxed text-black text-justify">
          <p className="font-bold">Children’s Privacy</p>
          <p>
            We do not permit children under 13 to create an account and do not
            knowingly collect personal information from children under the age
            of 13 without the consent and at the direction of a parent.
          </p>
          <p>
            Please contact us if you believe we have inadvertently collected
            information from a child under 13 without parental consent so that
            we may delete the information as soon as possible.
          </p>
          <p>
            We are committed to the following principles to protect children’s
            personal information:
          </p>
          <li>
            We do not collect personal information of a child we know to be
            under 13 without the consent, and active participation, of a parent.
          </li>
          <li>
            We collect only as much personal information as is reasonably
            necessary for the child to use the Service.
          </li>
          <li>We do not display targeted ads to child users.</li>
          <li>
            We do not use or disclose data collected from child users for
            targeted advertising or marketing purposes.
          </li>
          <li>
            We believe that parents should have control over their child’s data.
          </li>
          <li>
            We will not knowingly retain a child’s personal information after it
            is no longer needed for the purpose for which it was collected.
          </li>
          <li>
            We will never sell or rent a child’s personal information to third
            parties.
          </li>
          <li>
            We employ industry-standard network security and encryption
            technology to protect children’s personal information.
          </li>
        </div>

        <div className="mb-8 space-y-4 leading-relaxed text-black text-justify">
          <p className="font-bold">Links to Other Sites</p>
          <p>
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </p>
        </div>
        <div className="mb-8 space-y-4 leading-relaxed text-black text-justify">
          <p>This policy is effective as of 16/12/2024</p>
        </div>
        <div className="mb-8 space-y-4 leading-relaxed text-black text-justify">
          <p className="font-bold">Contact Us</p>
          <p>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us at
          </p>
          <p>
            <a
              href="mailto:hello@readwithjoey.com"
              className="text-blue-500 underline"
            >
              hello@readwithjoey.com
            </a>
          </p>
        </div>
      </div>
    </main>
  );
};

export default PrivacyPolicy;
