import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import books from "../../../models/book";

const Call2Action = () => {
  const ref = useRef(null);

  const largest = Array.from({ length: 5 }, (_, index) => index);
  const middle = Array.from({ length: 3 }, (_, index) => index);
  const smallest = Array.from({ length: 2 }, (_, index) => index);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end end"],
  });
  const evenTransform = useTransform(scrollYProgress, [0, 1], [0, 0]); // Faster scroll for even columns
  const oddTransform = useTransform(scrollYProgress, [0, 1], [0, -100]); // Slower scroll for odd columns
  const maxRows = 10;
  return (
    <section
      className=" bg-white mt-[80px] md:mt-[100px]  justify-center w-full  "
      ref={ref}
    >
      <motion.div className="sticky top-0  bottom-0 w-full pointer-events-none z-10  px-[8px] md:px-[5px]  ">
        <div className="absolute top-0 left-0 right-0 h-screen flex flex-col flex-nowrap items-center justify-center gap-y-[13px]">
          <div className="max-w-[288px] md:max-w-max w-full mx-5 md:w-[442px] p-[24px_20px] md:p-[32px_15px_40px] flex flex-col items-center justify-center gap-y-[14px] md:gap-y-[32px] bg-white/[0.8] backdrop-blur-[40px] rounded-[24px] md:rounded-[20px]  shadow-[-0_20px_40px_0_rgba(0,0,0,0.08)] md:shadow-lg pointer-events-auto">
            {/*  */}
            <div class="infinite-cards__heading block w-full text-[34px] md:text-[48px] text-navy  leading-[132%] tracking-[-0.03em] text-center font-[500]">
              <motion.div className="mb-[5vw] md:mb-[90x]  flex flex-row justify-center items-center ">
                <img
                  src="https://images.readwithjoey.com/joey/logos/joey-books-blue-logo.webp"
                  className="h-[120px] md:h-[150px] "
                  alt="Joey Logo"
                />
              </motion.div>
              Unlock endless stories
              <motion.div className="flex flex-col gap-3 md:flex-row justify-center items-center gap-x-[12px] md:gap-x-[16px] mt-[5vw] md:mt-[51px] px-6  ">
                <a
                  href="https://apps.apple.com/us/app/joey-books/id6465174102"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/stores/AppStore.png"
                    className=" md:h-[52px]  cursor-pointer"
                    alt="App Store"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.readwithjoey.joey_books"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/stores/PlayStore.png"
                    className=" md:h-[52px]  cursor-pointer"
                    alt="Google Play"
                  />
                </a>
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>
      <div class="w-full  min-h-screen pb-[100px] justify-center  flex flex-row   ">
        {/* largest */}
        {largest.map((index) => (
          <motion.div
            class="hidden lg:flex ml-[15px] mr-[15px] flex-col"
            style={{ y: index % 2 === 0 ? evenTransform : oddTransform }}
          >
            {books
              .slice(
                index * (books.length / 5),
                index * (books.length / 5) + Math.min(books.length / 5, maxRows)
              )
              .map((book, i) => {
                return (
                  <div class=" ">
                    <div className="  bg-white rounded-[9px] md:rounded-[18px] shadow-lg mb-[3rem]">
                      <img
                        src={`/covers/width/${book.cover}`}
                        alt="Cover"
                        class="w-full rounded-[9px] md:rounded-[18px]"
                      />
                    </div>
                  </div>
                );
              })}
          </motion.div>
        ))}
        {middle.map((index) => (
          <motion.div
            class="hidden md:flex lg:hidden  ml-[15px] mr-[15px] flex-col"
            style={{ y: index % 2 === 0 ? evenTransform : oddTransform }}
          >
            {books
              .slice(
                index * (books.length / 3),
                index * (books.length / 3) + Math.min(books.length / 3, maxRows)
              )
              .map((book, i) => {
                return (
                  <div class=" ">
                    <div className=" bg-white rounded-[9px] md:rounded-[18px] shadow-lg mb-[3rem]">
                      <img
                        src={`/covers/width/${book.cover}`}
                        alt="Cover"
                        class="w-full rounded-[9px] md:rounded-[18px]"
                      />
                    </div>
                  </div>
                );
              })}
          </motion.div>
        ))}
        {smallest.map((index) => (
          <motion.div class="flex md:hidden lg:hidden  ml-[15px] mr-[15px] flex-col">
            {books
              .slice(
                index * (books.length / 2),
                index * (books.length / 2) + Math.min(books.length / 2, maxRows)
              )
              .map((book, i) => {
                return (
                  <div class=" ">
                    <div className=" bg-white rounded-[9px] md:rounded-[18px] shadow-lg mb-[3rem]">
                      <img
                        src={`/covers/width/${book.cover}`}
                        alt="Cover"
                        class="w-full rounded-[9px] md:rounded-[18px]"
                      />
                    </div>
                  </div>
                );
              })}
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Call2Action;
