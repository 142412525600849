import React from "react";
import { FaInstagram, FaFacebook } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="w-full bg-blue overflow-hidden text-white">
      <div className="mx-auto flex w-full items-center justify-between px-4 py-6 sm:px-8">
        <span className="text-sm lg:text-lg">Copyright 2024</span>
        <div className="flex items-center space-x-4 sm:space-x-8">
          <a
            className="text-sm lg:text-lg flex items-center justify-between sm:space-x-2 space-x-0"
            href="https://www.facebook.com/people/Joey-Books-reading-app/61566947752878/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook size={32} />
            <span className="hidden sm:block">Facebook</span>
          </a>
          <a
            className="text-sm lg:text-lg flex items-center justify-between sm:space-x-2 space-x-0"
            href="https://www.instagram.com/readwithjoey"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={32} />
            <span className="hidden sm:block">Instagram</span>
          </a>

          <a className="text-sm lg:text-lg" href="/privacy-policy">
            Privacy Policy
          </a>
          {/* <a
            className="text-sm lg:text-lg"
            href="https:/www.apple.com/legal/internet-services/itunes/dev/stdeula/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
