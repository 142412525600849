import React, { useRef } from "react";
import { useInView } from "framer-motion";
export default function PublisherList() {
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true });
  return (
    <section
      className="w-full max-w-6xl mx-auto overflow-hidden"
      ref={containerRef}
      style={{
        transform: isInView ? "none" : "translateY(50px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1)  ",
      }}
    >
      <div>
        <h1 className="text-3xl text-black flex justify-center w-full font-[600]">
          Our Publishing Partners
        </h1>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 gap-y-9 pt-5 pb-10 ">
          <div className="flex items-center justify-center">
            <img
              alt="Affirm Press Logo"
              className="h-12 object-contain"
              src="/publishers/affirm-logo.jpg"
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              alt="Fremantle Press Logo"
              className="h-12 object-contain"
              src="/publishers/fremantle-press.jpg"
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              alt="Hachette Australia Logo"
              className="h-12 object-contain"
              src="/publishers/hachette-australia-logo.jpg"
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              alt="Hardie Grant Children's Publishing Logo"
              className="h-12 object-contain"
              src="/publishers/hardie-grant-childrens-publishing.jpg"
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              alt="Lonely Planet Logo"
              className="h-12 object-contain"
              src="/publishers/Lonely_Planet.png"
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              alt="UQP Logo"
              className="h-12 object-contain"
              src="/publishers/UQP_logo.jpg"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
